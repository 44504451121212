<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Many metals pack in cubic unit cells. The density of a metal and length of the unit cell can
        be used to determine the type of packing. For example, suppose that you have a fictional
        metal that has a density of
        <number-value :value="density" unit="\text{g/cm}^3," />
        and a unit cell side length of
        <latex-number :number="length.toFixed(3)" unit="\text{nm.}" />
        Assume that the metal has a molar mass of
        <number-value :value="molarMass" unit="\text{g/mol.}" />
      </p>

      <p class="mb-2">a) How many metal atoms are in exactly 1 cm<sup>3</sup>?</p>

      <calculation-input
        v-model="inputs.nAtoms1"
        class="mb-5"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{atoms}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) How many unit cells are in exactly 1 cm<sup>3</sup>?</p>

      <calculation-input
        v-model="inputs.nCells"
        class="mb-5"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{unit cells}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) How many metal atoms are there per unit cell? <b>Round to the nearest whole number</b>.
      </p>

      <calculation-input
        v-model="inputs.nAtoms2"
        class="mb-5"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{atoms/cell}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        d) The number of atoms per unit cell determined in part c) would suggest that the metal
        packs as a:
      </p>

      <v-radio-group v-model="inputs.cellType" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question403',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        nAtoms1: null,
        nCells: null,
        nAtoms2: null,
        cellType: null,
      },
      options: [
        {text: '$\\text{Simple cubic}$', value: 'simple'},
        {text: '$\\text{Body-centered cubic}$', value: 'bcc'},
        {text: '$\\text{Face-centered cubic}$', value: 'fcc'},
      ],
    };
  },
  computed: {
    density() {
      return this.taskState.getValueBySymbol('density').content;
    },
    molarMass() {
      return this.taskState.getValueBySymbol('molarMass').content;
    },
    densityFloat() {
      return this.density.toFloat();
    },
    molarMassFloat() {
      return this.molarMass.toFloat();
    },
    length() {
      return 1e7 / (((this.densityFloat / this.molarMassFloat) * 6.022e23) / 4) ** (1 / 3);
    },
  },
};
</script>
